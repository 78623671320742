import { ColumnProps } from "../../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge";
import { Image } from "@chakra-ui/react";
import { ColumnDate } from "../../../../../shared/components/CustomColumns/ColumnDate";
import { DateTime } from "luxon";

export const ExamenesColumns = (): ColumnProps[] => {

    const formatDuracion = (duracion: number) => {
        const horas = Math.floor(duracion / 3600);
        const minutos = Math.floor((duracion % 3600) / 60);
        const segundos = duracion % 60;

        const time = DateTime.fromObject({ hour: horas, minute: minutos, second: segundos });

        const formattedTime = time.toFormat('HH:mm:ss');

        return formattedTime;
    }

    return [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.nombre}
                    link={{
                        to: `/examenes/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'curso',
            field: 'curso.nombre',
            key: 'curso.nombre',
            body: (rowData: any) => (
                    <ColumnAvatarLabelText
                        label={rowData?.curso?.nombre}
                        customIcon={
                            rowData?.curso?.icono?.url ?
                                <Image
                                    src={rowData?.curso?.icono?.url}
                                    padding="0"
                                    boxSize="50px"
                                />
                                : undefined
                        }
                        link={{
                            to: `/examenes/${rowData.id}/perfil`,

                        }}
                    />
                )
        },
        {
            header: 'duracion',
            field: 'duracion',
            key: 'duracion',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.duracion ? formatDuracion(rowData?.duracion) : "---"}
                    link={{
                        to: `/examenes/${rowData.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: 'Intentos',
            field: 'numIntentos',
            key: 'numIntentos',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.numIntentos} link={{
                        to: `/examenes/${rowData.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: 'Total preguntas',
            field: 'meta.totalPreguntas',
            key: 'meta.totalPreguntas',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.meta?.totalPreguntas || 0}
                    link={{
                        to: `/examenes/${rowData.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: 'Estado',
            field: 'publicado',
            key: 'publicado',
            body: (rowData: any) => (
                <ColumnBadge
                    text={rowData?.publicado ? "Publicado" : "Oculto"}
                    ui={{
                        backgroundColor: rowData?.publicado ? "success_main" : "fail_main",
                        colorFont: rowData?.publicado ? "success_bg" : "fail_bg",
                        fontSize: "12px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.publicado ? "success_variant" : "fail_variant",
                            textTransform: "uppercase",
                            w: "fit-content",
                            textAlign: "center",
                        }
                    }}
                    link={{
                        to: `/examenes/${rowData.id}/perfil`,

                    }}
                />
            )
        },
        {
            header: 'Fecha de creacion',
            field: 'createdAt',
            key: 'createdAt',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                    link={{
                        to: `/examenes/${rowData.id}/perfil`,
                    }}
                />
            )
        },
    ]
}