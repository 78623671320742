import { Flex, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { updateUsuario } from "../../../../../shared/middlewares/users.middleware";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { CustomAvatar } from "../../../../../shared/components/Avatar/CustomAvatar";
import { loginViaId } from "../../../../../shared/middlewares/token.middleware";
import { UserTypesEnum } from "../../../../../shared/utils/Types/UserTypesEnum";
import { BiBook, BiGroup, BiUserCircle } from "react-icons/bi";
import { TabTable } from "../../TabItems/TabTable";
import { TabPerfil } from "../../TabItems/TabPerfil";
import { TabCursos } from "../../TabItems/TabCursos";
import { Skeleton } from "primereact/skeleton";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../../shared/context/client.context";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae";
import { getKey } from "../../../../../shared/middlewares/config.middleware";
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";
import { TabsRouterInt } from "../../../../../interfaces/ComponentsInt";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { GruposTutores } from "../../../components/TabsColumns/GruposTutotes";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { TabsRouter } from "../../../../../shared/components/TabsRouter/TabsRouter";

export const InformationTutor = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.GRUPOS_TUTOR });
    const moduloFUNDAE = useModuloFundae();
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const { user } = useAuthContex();
    const { data: usuario, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.USUARIOS,
        client: client
    });
    const { data: grupos, loading: loadingGrupos } = useDataRelationship({
        id: id,
        endpoint: EndpointTypes.TUTORES,
        client: client,
        relationship: RelationshipTypes.GRUPOS,
        query: query,
    });

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    const updateValue = (value: any) => {
        if (!id) return;

        if (Reflect.has(value, "emailGuia") || Reflect.has(value, "telefonoGuia")) value = { extraData: { ...usuario?.extraData, ...value } }

        if (Reflect.has(value, "porcentajeComision")) value = { ...value, porcentajeComision: Number(value.porcentajeComision) }

        return updateUsuario({
            id: id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Actualizacion correcta")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const handleLoginViaId = async () => {
        if (!usuario?.id) return;

        const { data } = await getKey("userURL", client);
        setIsLoggingIn(true);

        loginViaId({
            userId: usuario?.id,
            client: client
        })
            .then((response) => {
                const token = response?.data?.token?.token;
                const url = `${data.data}/login?loginById=true&token=${token}`;
                window.open(url, "_blank");
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                );

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message));
            })
            .finally(() => setIsLoggingIn(false));
    };

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Perfil",
            path: "perfil",
            icon: BiUserCircle,
            content:
                <TabPerfil
                    usuario={usuario}
                    updateValue={updateValue}
                    userType={UserTypesEnum.TUTOR}
                    setRefresh={Refresh}
                    loading={loading}
                />
        },
        {
            isAllowed: true,
            label: "Cursos",
            path: "cursos",
            icon: BiBook,
            content:
                <TabCursos
                    userId={id}
                />
        },
        {
            isAllowed: true,
            label: "Grupos",
            path: "grupos",
            icon: BiGroup,
            content:
                <TabTable
                    data={grupos}
                    columns={GruposTutores({ moduloFUNDAE })}
                    loading={loadingGrupos}
                    userType={UserTypesEnum.TUTOR}
                    setQuery={setQuery}
                    query={query}
                />
        },

        // TODO: Proxima implementación
        // {
        //     isAllowed: true,
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.USER}
        //         />
        // },
    ]

    return (
        <Topbar
            title={
                loading ?
                    <Flex gap="15px" alignItems="center">
                        <Skeleton shape="circle" size="55px"></Skeleton>

                        <Flex direction="column" gap="8px">
                            <Skeleton height="22px" width="250px"></Skeleton>

                            <Skeleton height="18px" width="250px"></Skeleton>
                        </Flex>
                    </Flex>
                    :
                    <Flex gap="15px" alignItems="center">
                        <CustomAvatar
                            name={usuario?.nombre + usuario?.apellidos || ""}
                            size="55px"
                            src={usuario?.avatar?.url}
                        />

                        <Flex direction="column" justifyContent="space-between">
                            <Text
                                color="font"
                                fontSize="17px"
                                fontWeight="700"
                                lineHeight="120%"
                            >
                                {usuario?.nombre} {usuario?.apellidos}
                            </Text>

                            <Text
                                color="font"
                                fontSize="12px"
                                fontWeight="500"
                            >
                                {usuario?.email}
                            </Text>
                        </Flex>
                    </Flex>
            }
            buttonBack="/usuarios/tutores"
        >
            <Flex
                direction="column"
                px="30px"
                py="12px"
                borderTop="1px solid #EAEAEA"
            >
                <TabsRouter
                    tabs={tabs}
                    buttons={[
                        {
                            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
                            paths: ["perfil", "cursos", "grupos"],
                            button: {
                                label: "Ingresar como tutor",
                                isDisabled: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? false : true,
                                onClick: handleLoginViaId,
                                isLoading: isLoggingIn,
                            }
                        }
                    ]}
                />
            </Flex>
        </Topbar>
    );
}