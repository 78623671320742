import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Image,
    Text,
    Flex,
    useToast,
    Checkbox,
    FormLabel,
} from '@chakra-ui/react'
import { EmpresasInt } from '../../../interfaces/EmpresasInt';
import { InformationInput } from '@imaginagroup/bit-components.ui.elements.information-input';
import { useClient } from '../../../shared/context/client.context';
import { useUserPolicy } from '../../../shared/hooks/PermissionPolicy';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/Types/StatusEnumTypes';
import { InformationFilepond } from '../../../shared/components/Information/InformationFilepond';
import { defaultIcon } from '../../Onboarding/components/OnboardingForm';
import { updateEmpresa } from '../../../shared/middlewares/empresas.middleware';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { useEffect, useState } from 'react';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    empresa: EmpresasInt | null;
    Refresh: () => void;
}

export const EditEmpresaDrawer = ({ empresa, isOpen, onClose, Refresh }: Props) => {
    const { gestion } = useUserPolicy();
    const client = useClient();
    const toast = useToast();
    const [diasSeleccionados, setDiasSeleccionados] = useState<string[]>([]);
    const [extraInfoState, setExtraInfoState] = useState<any>(null);
    const [horarioState, setHorarioState] = useState<any>(null);

    useEffect(() => {
        if (isOpen && empresa) {
            if (empresa.extraInfo?.horario?.dias) {
                setDiasSeleccionados(empresa.extraInfo.horario.dias.split(' ').filter(Boolean));
            } else {
                setDiasSeleccionados([]);
            }
            setExtraInfoState(empresa.extraInfo || null);
            setHorarioState(empresa.extraInfo?.horario || null);
        } else {
            setDiasSeleccionados([]);
            setExtraInfoState(null);
            setHorarioState(null);
        }
    }, [isOpen, empresa]);

    const handleDiaChange = (dia: string) => {
        const oldSelect = diasSeleccionados;

        let nuevosDias;
        if (diasSeleccionados.includes(dia)) {
            nuevosDias = diasSeleccionados.filter(d => d !== dia);
        } else {
            nuevosDias = [...diasSeleccionados, dia];
        }

        nuevosDias.sort((a, b) => ["L", "M", "X", "J", "V", "S", "D"].indexOf(a) - ["L", "M", "X", "J", "V", "S", "D"].indexOf(b));

        setDiasSeleccionados(nuevosDias);

        try {
            const newHorario = {
                ...horarioState,
                dias: nuevosDias.join(' ')
            };
            setHorarioState(newHorario);
            updateValue({
                extraInfo: {
                    ...extraInfoState,
                    horario: newHorario
                }
            });
        } catch (err) {
            setDiasSeleccionados(oldSelect);
        }
    };

    const updateValue = (value: any) => {
        if (!empresa?.id) return;

        let updatedValue = value;

        if (value.extraInfo) {
            updatedValue = {
                ...value,
                extraInfo: {
                    ...extraInfoState,
                    ...value.extraInfo,
                    horario: {
                        ...horarioState,
                        ...value.extraInfo.horario
                    }
                }
            };
            setExtraInfoState(updatedValue.extraInfo);
            setHorarioState(updatedValue.extraInfo.horario);
        }

        return updateEmpresa({
            id: empresa?.id,
            data: updatedValue,
            client: client
        })
            .then(() => {
                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => {
                onClose();
                setDiasSeleccionados([]);
                setExtraInfoState(null);
                setHorarioState(null);
            }}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="50vw"
                minW="50vw"
                w="50vw"
            >
                <DrawerBody
                    className='scroll-bar'
                >
                    <Flex
                        direction="column"
                        gap="50px"
                        px="10px"
                    >
                        <Text
                            color={"font"}
                            fontSize="20px"
                            fontWeight="600"
                            mt="20px"
                        >
                            Datos de la empresa
                        </Text>

                        <Flex
                            direction="column"
                            gap="40px"
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={empresa?.nombre || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="cif"
                                    label="cif"
                                    defaultValue={empresa?.cif || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    defaultValue={empresa?.email}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="numeroExpediente"
                                    label="Nº de expediente"
                                    defaultValue={empresa?.numeroExpediente || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={empresa?.telefono || ""}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="sector"
                                    label="sector"
                                    defaultValue={empresa?.sector || ""}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="web"
                                    label="web"
                                    defaultValue={empresa?.web || ""}
                                    updateValue={updateValue}
                                />
                            </Flex>


                            <Flex
                                direction="column"
                                gap="10px"
                            >
                                <FormLabel
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="font"
                                    textTransform="uppercase"
                                >
                                    Icono
                                </FormLabel>

                                <Flex
                                    alignItems="center"
                                >
                                    <Flex
                                        flex="1"
                                    >
                                        {empresa?.icono?.url ?
                                            <Image
                                                m="auto"
                                                boxSize="75px"
                                                rounded="10px"
                                                src={empresa?.icono?.url}
                                            />
                                            :
                                            <Image
                                                src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                                m="auto"
                                                boxSize="75px"
                                                rounded="10px"
                                            />
                                        }
                                    </Flex>

                                    <Flex
                                        flex="1"
                                    >
                                        <InformationFilepond
                                            noHead={true}
                                            name="icono"
                                            label="Icono"
                                            putEP={`${client}/empresas/${empresa?.id}`}
                                            isDisabled={!empresa?.id || gestion}
                                            setRefresh={Refresh}
                                            labelIdle="Arrastra o selecciona el icono"
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="direccion"
                                    label="Dirección"
                                    defaultValue={extraInfoState?.direccion || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="codigoPostal"
                                    label="Codigo Postal"
                                    defaultValue={extraInfoState?.codigoPostal || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="localidad"
                                    label="Localidad"
                                    defaultValue={extraInfoState?.localidad || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />
                            </Flex>

                            <Text
                                fontSize="12px"
                                fontWeight="600"
                                color="font"
                                textTransform="uppercase"
                            >
                                Responsable
                            </Text>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="responsable"
                                    label="Nombre"
                                    defaultValue={extraInfoState?.responsable || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="telefonoContacto"
                                    label="Teléfono"
                                    defaultValue={extraInfoState?.telefonoContacto || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />
                            </Flex>

                            <Text
                                fontSize="12px"
                                fontWeight="600"
                                color="font"
                                textTransform="uppercase"
                                mb="10px"
                            >
                                Horario
                            </Text>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                                mb="10px"
                            >
                                <FormLabel
                                    flex="1"
                                    fontSize="12px"
                                    fontWeight="600"
                                    letterSpacing="0.24px"
                                    textTransform="uppercase"
                                >
                                    Primer Tramo
                                </FormLabel>

                                <Flex
                                    gap="10px"
                                    alignItems="center"
                                    flex="1"
                                >
                                    <InformationInput
                                        name="inicioTramo1"
                                        defaultValue={horarioState?.inicioTramo1 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />

                                    <Text>
                                        -
                                    </Text>

                                    <InformationInput
                                        name="finTramo1"
                                        defaultValue={horarioState?.finTramo1 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <FormLabel
                                    flex="1"
                                    fontSize="12px"
                                    fontWeight="600"
                                    letterSpacing="0.24px"
                                    textTransform="uppercase"
                                >
                                    Segundo Tramo
                                </FormLabel>

                                <Flex
                                    gap="10px"
                                    alignItems="center"
                                    flex="1"
                                >
                                    <InformationInput
                                        name="inicioTramo2"
                                        defaultValue={horarioState?.inicioTramo2 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />

                                    <Text>
                                        -
                                    </Text>

                                    <InformationInput
                                        name="finTramo2" defaultValue={horarioState?.finTramo2 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {["L", "M", "X", "J", "V", "S", "D"]?.map((dia: string) => (
                                    <Checkbox
                                        key={dia}
                                        gap="10px"
                                        flexDirection="row-reverse"
                                        fontSize="12px"
                                        fontWeight="600"
                                        color="font"
                                        value={dia}
                                        isChecked={diasSeleccionados.includes(dia)}
                                        onChange={() => handleDiaChange(dia)}
                                    >
                                        {dia}
                                    </Checkbox>
                                ))}
                            </Flex>

                            <Flex
                                direction="column"
                                gap="10px"
                                mb="20px"
                            >
                                <FormLabel
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="font"
                                    textTransform="uppercase"
                                >
                                    Cuño / Firma
                                </FormLabel>

                                <Flex
                                    alignItems="center"
                                >
                                    <Flex
                                        flex="1"
                                    >
                                        {empresa?.imagen?.url ?
                                            <Image
                                                m="auto"
                                                h="75px"
                                                rounded="10px"
                                                src={empresa?.imagen?.url}
                                            />
                                            :
                                            <Image
                                                src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                                m="auto"
                                                h="75px"
                                                rounded="10px"
                                            />
                                        }
                                    </Flex>

                                    <Flex
                                        flex="1"
                                    >
                                        <InformationFilepond
                                            noHead={true}
                                            name="imagen"
                                            label="Firma"
                                            putEP={`${client}/empresas/${empresa?.id}`}
                                            isDisabled={!empresa?.id || gestion}
                                            setRefresh={Refresh}
                                            labelIdle="Arrastra o selecciona la firma"
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}