import { Image, useToast } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../shared/components/Table/Table";
import { defaultIcon, IconBox } from "../../../../../shared/components/Icons/IconBox";
import { AlertsCurso } from "../../../../../shared/components/CustomElements/AlertsCurso";
import { Actions } from "../../../../../shared/components/TableElements/Actions";
import { copyCurso } from "../../../../../shared/middlewares/cursos.middleware";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../../shared/context/client.context";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";

interface Props {
    moduloFUNDAE: boolean;
    gestion: boolean;
    onEventRemove: (event: any) => void;
    Refresh: () => void;
}

export const CursosColumns = ({ moduloFUNDAE, gestion, onEventRemove, Refresh }: Props): ColumnProps[] => {
    const client = useClient();
    const toast = useToast();

    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre}
                    customIcon={
                        rowData?.icono?.url ?
                            <Image
                                src={rowData?.icono?.url}
                                padding="0"
                                boxSize="50px"
                            />
                            : undefined
                    }
                    customAlerts={
                        moduloFUNDAE ?
                            <AlertsCurso curso={rowData} />
                            :
                            undefined

                    }
                    link={{
                        to: `/cursos/${rowData?.id}/configuracion`,
                    }}
                />
            ),
        },
        {
            header: 'grupos activos',
            field: 'gruposActivos',
            key: 'gruposActivos',
            body: (rowData: any) =>
                <ColumnSimpleText
                    text={rowData?.meta?.totalGruposActivos + " grupos"}
                    link={{
                        to: `/cursos/${rowData?.id}/configuracion`,
                    }}
                />
        },
        {
            header: 'grupos asignados',
            field: 'gruposAsignados',
            key: 'gruposAsignados',
            body: (rowData: any) =>
                <ColumnSimpleText
                    text={rowData?.meta?.totalGrupos + " grupos"}
                    link={{
                        to: `/cursos/${rowData?.id}/configuracion`,
                    }}
                />
        },
        {
            header: 'matriculas activas',
            field: 'matriculasActivas',
            key: 'matriculasActivas',
            body: (rowData: any) =>
                <ColumnSimpleText
                    text={rowData?.meta?.totalMatriculasActivas + " matriculas"}
                    link={{
                        to: `/cursos/${rowData?.id}/configuracion`,
                    }}
                />
        },
        {
            header: "",
            field: "acciones",
            key: "acciones",
            body: (rowData: any) => (
                <Actions
                    duplicate={{
                        title: "Copiar curso",
                        onClick: () => {
                            copyCurso({
                                slug: rowData?.slug,
                                client: client
                            })
                                .then(() => {
                                    Refresh()
                                    toastNotify(toast, StatusEnumTypes.SUCCESS, "Curso copiado correctamente")
                                })
                                .catch((error: any) => {
                                    const errors = handleErrors(
                                        error?.response?.data?.errors,
                                        error?.response?.status
                                    )

                                    errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                                })
                        },
                        isDisabled: gestion,
                        disableAlert: gestion ? "No tienes permisos para esta acción" : undefined
                    }}
                    remove={{
                        title: "Eliminar curso",
                        onClick: () => {
                            onEventRemove(rowData)
                        },
                        isDisabled: rowData?.meta?.totalMatriculasActivas !== "0" || rowData?.meta?.totalGrupos !== "0" || gestion,
                        disableAlert: gestion ? "No tienes permisos para esta acción" : "No puedes eliminar un curso con matriculas activas o grupos asignados",
                    }}
                />
            )
        }
    ];
}