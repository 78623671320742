
import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import "primereact/resources/themes/viva-light/theme.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterController } from './shared/components/RouterController/RouterController';
import { useEffect, useState } from 'react';
import { UserInt } from './interfaces/UserInt';
import { toastNotify } from './shared/utils/functions/toastNotify';
import { StatusEnumTypes } from './shared/utils/Types/StatusEnumTypes';
import { AuthContext } from './shared/context/auth.context';
import { ClientInt } from './shared/services/http.service';
import { setClientRole } from './shared/utils/functions/setClientRole';
import { ClientContext } from './shared/context/client.context';
import { ChannelProvider } from 'ably/react';
import ClipboardJS from "clipboard";
import { useSelectChannel } from './shared/hooks/useSelectChannel';
import { OnboardingCard } from './shared/components/Cards/OnboardingCard';
import { pdfjs } from 'react-pdf';
import { Sidebar } from './shared/components/Sidebar/Sidebar';
import { forceInfoTenant } from './shared/middlewares/tenant.middleware';
import { UserSubTypeEnum } from './shared/utils/Types/UserRolEnum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from './theme';
import { useTenantInfo } from './shared/hooks/useTenantInfo';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function App() {
  const channel = useSelectChannel();
  const { handleGetInfo, handleSetInfo } = useTenantInfo()

  const login = async (
    jwt: string,
    user: UserInt,
    navigate: (path: string) => void
  ) => {
    const client = setClientRole(user?.role?.nombre)
    setClient(client)

    localStorage.removeItem('tokenPanel');
    localStorage.removeItem('perfilPanel');

    const { produccion } = await forceInfoTenant(jwt, client)
    handleSetInfo({ key: "produccion", value: produccion })

    const perfilUser: UserInt = {
      auth: true,
      id: user?.id,
      email: user?.email,
      username: user?.username ? user.username : '',
      avatar: {
        url: user.avatar?.url ? user.avatar?.url : '',
      },
      nombre: user?.nombre,
      apellidos: user?.apellidos ? user?.apellidos : null,
      localidad: user?.localidad ? user.localidad : null,
      telefono: user?.telefono ? user.telefono : null,
      role: user?.role?.nombre ? {
        nombre: user?.role?.nombre
      } : null,
      sepe: user?.sepe !== null && user?.sepe !== undefined ? user?.sepe : null,
      extraData: user?.extraData ? {
        subType: user?.extraData?.subType
      } : null,
      config: user?.config ? user?.config : null
    }

    localStorage.setItem('tokenPanel', jwt)
    localStorage.setItem('perfilPanel', JSON.stringify(perfilUser))

    setUser(perfilUser);

    if (!produccion) return navigate("/onboarding")
    else if (!user?.config?.cursos) return navigate("/cursos")
    else return navigate('/matriculas')
  };

  const logout = (
    message: string,
    navigate: (path: string) => void,
    toast: any,
  ) => {
    localStorage.removeItem('tokenPanel');
    localStorage.removeItem('perfilPanel');

    setUser({
      auth: false,
      id: null,
      email: null,
      username: null,
      avatar: null,
      nombre: null,
      apellidos: null,
      localidad: null,
      telefono: null,
      role: null,
      sepe: null,
      extraData: null,
      config: null
    });

    navigate('/login')
    toastNotify(toast, StatusEnumTypes.INFO, message)
  };

  const refreshUser = async (updateUser: Partial<UserInt>) => {
    for (const [key, value] of Object.entries(updateUser)) {
      if (key) {
        setUser((prev: UserInt) => ({
          ...prev,
          [key]: value
        }));
      }
    }
  };

  const perfilUser: UserInt = JSON.parse(localStorage.getItem('perfilPanel') || '{}');

  const [user, setUser] = useState<UserInt>({
    auth: localStorage.getItem('tokenPanel') ? true : false,
    id: perfilUser?.id ? perfilUser.id : null,
    email: perfilUser?.email ? perfilUser.email : null,
    username: perfilUser?.username ? perfilUser.username : null,
    avatar: perfilUser?.avatar ? {
      url: perfilUser.avatar?.url
    } : null,
    nombre: perfilUser?.nombre ? perfilUser.nombre : null,
    apellidos: perfilUser?.apellidos ? perfilUser.apellidos : null,
    localidad: perfilUser?.localidad ? perfilUser.localidad : null,
    telefono: perfilUser?.telefono ? perfilUser.telefono : null,
    role: perfilUser?.role ? {
      nombre: perfilUser?.role?.nombre
    } : null,
    sepe: perfilUser?.sepe !== null && perfilUser?.sepe !== undefined ? perfilUser?.sepe : null,
    extraData: perfilUser?.extraData ? {
      subType: perfilUser?.extraData?.subType
    } : null,
    config: perfilUser?.config ? perfilUser?.config : null
  });

  useEffect(() => {
    localStorage.setItem('perfilPanel', JSON.stringify(user))

  }, [user])

  const [client, setClient] = useState<ClientInt>(setClientRole(user?.role?.nombre));

  const clipboard = new ClipboardJS(".copy-btn");
  const icono  = handleGetInfo({ key: "icono" });
  const produccion = handleGetInfo({ key: "produccion" });

  const completedConfig = () => {
    if (user?.extraData?.subType === UserSubTypeEnum.TOTAL && produccion) {
      if (
        user?.config &&
        user?.config?.cursos &&
        user?.config?.plantillas &&
        user?.config?.grupos &&
        user?.config?.matriculas &&
        user?.config?.alumnos &&
        user?.config?.tutores &&
        user?.config?.empresasOrganizadoras
      ) return true
      else return false
    }
    else return true
  }

  const queryClient = new QueryClient({})

  useEffect(() => {
    if (icono) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = icono;
    }
  }, [icono]);

  return (
    <Router basename='/'>
      <AuthContext.Provider value={{ user, setUser, refreshUser, login, logout }}>
        <QueryClientProvider client={queryClient}>
          <ClientContext.Provider value={client}>
            <ChakraProvider theme={theme}>
              <ChannelProvider channelName={`${channel}-${user?.id}`}>
                <Flex
                  pos="relative"
                  maxH="100vh"
                  overflow="hidden"
                >
                  <Box>
                    {(user.auth && produccion) && <Sidebar />}
                  </Box>


                  <Box flex="1">
                    <RouterController />
                  </Box>

                  {(user.auth && !completedConfig()) && <OnboardingCard user={user} />}
                </Flex>
              </ChannelProvider>
            </ChakraProvider>
          </ClientContext.Provider>
        </QueryClientProvider>
      </AuthContext.Provider>
    </Router>
  )
}

export default App