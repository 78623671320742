import { Flex, Tooltip } from "@chakra-ui/react";
import { CursosInt, LeccionInt, ModulosInt } from "../../../../../interfaces/CursosInt";
import { EditModule } from "../../../components/utils/EditModule";
import { useEffect, useState } from "react";
import { DndAcordeon } from "../../../components/DndAcordeon/Contenidos";
import { AddLeccion } from "../../../components/utils/AddLeccion";
import { EditLeccion } from "../../../components/utils/EditLeccion";
import { NoSelectContenido } from "../../../components/utils/NoSelecContenido";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../shared/hooks/useData";
import { useClient } from "../../../../../shared/context/client.context";
import { useParams } from "react-router-dom";

export enum TipoSelect {
    MODULO = "MODULO",
    LECCION = "LECCION",
    NUEVA_LECCION = "NUEVA_LECCION"
}

export const TabContenidoCurso = () => {
    const { id } = useParams();
    const client = useClient();

    const { data, Refresh, loading } = useData({
        endpoint: EndpointTypes.MODULOS,
        client: client,
        specificQuery: {
            curso: id!,
            sortBy: "orden",
            order: "asc",
            limit: 100,
        },
        ignoreRequest: !id
    })

    const [refreshLeccion, setRefreshLeccion] = useState<boolean>(false)

    const [selected, setSelected] = useState<{
        type: TipoSelect,
        data: ModulosInt | LeccionInt | null
    }>()

    return (
        <Flex
            gap="30px"
            h={"calc(100vh - 170px)"}
        >
            <Flex
                position={"relative"}
                opacity={selected?.type === TipoSelect.NUEVA_LECCION ? 0.5 : 1}
                w="360px"
            >
                <DndAcordeon
                    selected={selected}
                    setSelected={setSelected}
                    dataModulos={data?.data}
                    setRefreshLeccion={setRefreshLeccion}
                    refreshLeccion={refreshLeccion}
                    setRefreshModulos={Refresh}
                    loadingModulos={loading}
                />

                {/* <Tooltip
                    label="Primero debes guardar la lección actual."
                    hasArrow
                    placement="right"
                    fontSize="14px"
                    color={"purewhite"}
                    bg="pureblack"
                    rounded="6px"
                    py="10px"
                    px="15px"
                    textAlign="center"
                    isDisabled={selected?.type !== TipoSelect.NUEVA_LECCION}
                > */}
                <Flex
                    display={selected?.type === TipoSelect.NUEVA_LECCION ? "block" : "none"}
                    pos={"absolute"}
                    bottom={"0"}
                    right={"0"}
                    top={"0"}
                    left={"0"}
                    bg={"transparent"}
                    cursor={"not-allowed"}
                />
                {/* </Tooltip> */}
            </Flex>

            <Flex
                flex="1"
                p="10px"
            >
                {!selected &&
                    <NoSelectContenido />
                }

                {selected?.type === TipoSelect.MODULO &&
                    <EditModule
                        setSelected={setSelected}
                        modulo={selected?.data as ModulosInt}
                        setRefreshModulos={Refresh}
                    />
                }

                {selected?.type === TipoSelect.LECCION &&
                    <EditLeccion
                        setSelected={setSelected}
                        leccion={selected?.data as LeccionInt}
                        setRefreshLeccion={setRefreshLeccion}
                    />
                }

                {selected?.type === TipoSelect.NUEVA_LECCION &&
                    <AddLeccion
                        data={selected?.data as Partial<LeccionInt>}
                        setRefreshLeccion={setRefreshLeccion}
                        setRefreshModulos={Refresh}
                        setSelected={setSelected}
                    />
                }
            </Flex>
        </Flex>
    );
}