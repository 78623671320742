import { Flex, Progress, Text } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { Actions } from "../../../../shared/components/TableElements/Actions";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { handleLinkOpen } from "../../../../shared/utils/functions/linkOpen";
import { ColumnDate } from "../../../../shared/components/CustomColumns/ColumnDate";

interface Props {
    onEventRemove: (event: any) => void;
    onEventBajaMatricula: (event: any) => void;
    contenido: boolean;
}

export const MatriculasColumns = ({ onEventRemove, onEventBajaMatricula, contenido }: Props): ColumnProps[] => {
    const { user } = useAuthContex();

    const columns: ColumnProps[] = [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    text={!rowData?.heredaFecha ? "Fechas distintas al grupo" : undefined}
                    customIcon={
                        <CustomAvatar name={rowData?.user?.nombre + rowData?.user?.apellidos} src={rowData?.user?.avatar?.url} size="35px" />
                    }
                    link={{
                        to: `/matriculas/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.email}
                    link={{
                        to: `/matriculas/${rowData.id}/perfil`,
                    }}
                />
            )
        },
        {
            header: 'Progreso',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <Flex gap="5px" alignItems="center" pr="10%">
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.progresoLecciones.toFixed(0) || 0} %
                    </Text>

                    <Progress
                        value={rowData?.progresoLecciones || 0}
                        flex="1"
                        h="7px"
                        rounded="10px"
                        sx={{ '& > div': { background: "secondary" } }}
                    />
                </Flex>
            )
        },
        {
            header: 'Tutor',
            field: 'tutor',
            key: 'tutor',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.tutor?.nombre + " " + rowData?.tutor?.apellidos}
                    link={{
                        to: `/matriculas/${rowData.id}/perfil`,
                    }}
                />
            )
        },
        {
            header: 'Creación',
            field: 'matriculas.created_at',
            key: 'cratedAt',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                    link={{
                        to: `/matriculas/${rowData.id}/perfil`,
                    }}
                />
            )
        }
    ]

    const extraColumns: ColumnProps[] = [
        {
            header: "",
            field: "",
            key: "",
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Eliminar matricula",
                            isDisabled: (rowData?.grupo?.estado === "0" || rowData?.grupo?.estado === "2" || contenido),
                            disableAlert: contenido
                                ? "No tienes permisos para esta acción"
                                : "No se puede eliminar una matrícula de un grupo que esta activo",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                        extra={{
                            title: "Dar de baja matricula",
                            isDisabled: contenido,
                            disableAlert: contenido
                                ? "No tienes permisos para esta acción"
                                : undefined,
                            onClick: () => {
                                onEventBajaMatricula(rowData)
                            }
                        }}
                    />
                </Flex>
            )
        }
    ]

    return isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? [...columns, ...extraColumns] : columns
}