export enum LeccionTipoEnum {
    // VIDEO = 'video',
    // MARKDOWN = 'markdown',
    // DIAPOSITIVA = 'diapositiva',
    // ENTREGABLE = 'entregable',
    // RECURSO = 'recurso',
    // ZOOM = 'zoom',
    // ENUNCIADO = 'enunciado',

    FICHERO = 'fichero',
    EMBEBIDO = 'embebido',
    TEXTO = 'texto',
    ENTREGABLE = 'entregable',
    URL = 'url',
    ZOOM = 'zoom'
}


export enum ComponenteLeccionEnum {
    VIDEO = 'video',
    PDF_VIEWER = 'pdf_viewer',
    MARKDOWN_PREVIEW = 'markdown_preview',
    IFRAME = 'iframe',
    LINK = 'link',
    IMAGEN = 'imagen',
}


export enum SubtipoLeccion {
    FICHERO = 'fichero',
    EMBEBIDO = 'embebido',
    TEXTO = 'texto',
}