import { BiGroup, BiBuilding, BiFolder, BiLabel, BiUserPin, BiBook, BiNews, BiBarChartSquare, BiLineChart, BiWalletAlt, BiCloudUpload } from "react-icons/bi"
import { isInspector, isRoleAllowed } from "../../utils/functions/validateRol";
import { UserRolEnum } from "../../utils/Types/UserRolEnum";
import { useAuthContex } from "../../context/auth.context";
import { MdAlternateEmail, MdOutlineEmail } from "react-icons/md";
import { LuSettings } from "react-icons/lu";
import { LuFileBadge } from "react-icons/lu";
import { useUserPolicy } from "../../hooks/PermissionPolicy";
import { BaseSidebar } from "./Components/BaseSidebar";
import { HiOutlineViewGridAdd } from "react-icons/hi";

export const Sidebar = () => {
    const { user } = useAuthContex();
    const { contenido, gestion } = useUserPolicy();

    return (
        <BaseSidebar
            secciones={(user?.role?.nombre !== UserRolEnum.SUPERADMIN) && !isInspector(user)
                ?
                [
                    {
                        isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido),
                        navlink: {
                            path: "/dashboard",
                            icon: BiLineChart,
                            label: "Dashboard",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
                        navlink: {
                            path: "/cursos",
                            icon: BiBook,
                            label: "Cursos",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.SUPERVISOR], user?.role?.nombre),
                        navlink: {
                            path: "/grupos",
                            icon: BiGroup,
                            label: "Grupos",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.SUPERVISOR], user?.role?.nombre),
                        navlink: {
                            path: "/matriculas",
                            icon: BiFolder,
                            label: "Matrículas",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.SUPERVISOR], user?.role?.nombre),
                        navlink: {
                            path: "/usuarios/alumnos",
                            icon: BiUserPin,
                            label: "Usuarios",
                        }
                    },

                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido,
                        navlink: {
                            path: "/empresas",
                            icon: BiBuilding,
                            label: "Empresas",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido,
                        navlink: {
                            path: "/facturacion/pendientes",
                            icon: BiWalletAlt,
                            label: "Facturación",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido,
                        navlink: {
                            path: "/datos",
                            icon: BiCloudUpload,
                            label: "Datos Alumnos",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
                        navlink: {
                            path: "/rutas",
                            icon: BiNews,
                            label: "Hojas de Ruta",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
                        navlink: {
                            path: "/correcciones",
                            icon: HiOutlineViewGridAdd,
                            label: "Correcciones",
                        }
                    },
                    {
                        isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion),
                        navlink: {
                            path: "/seguimiento/correcciones",
                            icon: BiBarChartSquare,
                            label: "Pendientes",
                        }
                    },
                    {
                        isAllowed: (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.SUPERVISOR], user?.role?.nombre) && !contenido),
                        navlink: {
                            path: "/informes",
                            icon: BiLabel,
                            label: "Informes",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido,
                        navlink: {
                            path: "/emails/enviados",
                            icon: MdAlternateEmail,
                            label: "Emails",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido && !gestion,
                        navlink: {
                            path: "/plantillas/todo",
                            icon: MdOutlineEmail,
                            label: "Plantillas",
                        }
                    },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion,
                        navlink: {
                            path: "/examenes",
                            icon: LuFileBadge,
                            label: "Exámenes",
                        }
                    },
                    // {
                    //     isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
                    //     navlink: {
                    //         path: "/feedback",
                    //         icon: RiFeedbackLine,
                    //         label: "Feedback",
                    //     }
                    // },
                    // {
                    //     isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido),
                    //     navlink: {
                    //         path: "/audits",
                    //         icon: RiSwapBoxLine,
                    //         label: "Audits",
                    //     }
                    // },
                    {
                        isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido && !gestion,
                        navlink: {
                            path: "/configuracion/mis-empresas",
                            icon: LuSettings,
                            label: "Configuración",
                        }
                    },
                ]
                : (isRoleAllowed([], user?.role?.nombre) || isInspector(user)) ?
                    [
                        {
                            isAllowed: true,
                            navlink: {
                                path: "/grupos-inspector",
                                icon: BiGroup,
                                label: "Grupos",
                            }
                        },
                        {
                            isAllowed: true,
                            navlink: {
                                path: "alumnos-inspector",
                                icon: BiUserPin,
                                label: "Alumnos",

                            }
                        },
                    ]
                    : user?.role?.nombre === UserRolEnum.SUPERADMIN ?
                        [
                            {
                                isAllowed: true,
                                navlink: {
                                    path: "/tenant",
                                    icon: BiBuilding,
                                    label: "Empresas",
                                }
                            },
                        ]
                        :
                        []
            }
        />
    );
};