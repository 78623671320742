import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { OnboardingStep } from "../../../../shared/components/Cards/OnboardingStep";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { CardPlantilla } from "../../components/CardPlantilla";
import { TabsRouterInt } from "../../../../interfaces/ComponentsInt";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { PiStudentFill } from "react-icons/pi";
import { HiOutlineDocumentText, HiTemplate } from "react-icons/hi";
import { TestPlantillasModal } from "../../components/TestPlantillasModal";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { TabsRouter } from "../../../../shared/components/TabsRouter/TabsRouter";
import { useLocation } from "react-router-dom";

export const TabsPlantillas = () => {
    const { user } = useAuthContex();
    const { total } = useUserPolicy();
    const client = useClient();
    const [showStep, setShowStep] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();

    const { data: plantillas, loading, error } = useData({
        client: client,
        endpoint: EndpointTypes.PLANTILLAS,
        specificQuery: location.pathname?.includes("general") ? { "categoria": "general" }
            : location.pathname?.includes("alumno") ? { "categoria": "alumno" }
                : location.pathname?.includes("tutor") ? { "categoria": "tutor" }
                    : location.pathname?.includes("supervisor") ? { "categoria": "supervisor" }
                        : undefined,
        query: {
            limit: 20
        }
    })

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "todo",
            label: "Todo",
            icon: HiTemplate,
            content:
                <Flex
                    gap="40px"
                    flexWrap={"wrap"}
                >
                    {plantillas?.data?.map((plantilla: any, index: number) => (
                        <CardPlantilla
                            key={index}
                            plantilla={plantilla}
                            loading={loading}
                            error={error}
                        />
                    ))}
                </Flex>
        },
        {
            isAllowed: true,
            label: "General",
            path: "general",
            icon: HiOutlineDocumentText,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        {
            isAllowed: true,
            label: "Alumno",
            path: "alumno",
            icon: PiStudentFill,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        {
            isAllowed: true,
            label: "Tutor",
            path: "tutor",
            icon: LiaChalkboardTeacherSolid,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        {
            isAllowed: true,
            label: "Supervisor",
            path: "supervisor",
            icon: MdOutlineSupervisorAccount,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
    ]

    const completedConfig = () => {
        if (
            user?.config &&
            user?.config?.cursos &&
            user?.config?.grupos &&
            user?.config?.matriculas &&
            user?.config?.alumnos &&
            user?.config?.tutores &&
            user?.config?.empresasOrganizadoras
        ) return true
        else return false
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Plantillas
                </Text>
            }
        >
            <Flex
                direction="column"
                p="30px"
                minH="calc(100vh - 200px)"
                pos={"relative"}
            >
                <TabsRouter
                    tabs={tabs}
                    buttons={[
                        {
                            isAllowed: true,
                            paths: ["todo", "general", "alumno", "tutor", "supervisor"],
                            tooltip: {
                                label: !plantillas || plantillas?.meta?.total === 0
                                    ? "Debe tener plantillas creadas para poder probarlas"
                                    : "Debe completar toda la configuración para poder probar las plantillas",
                                hasArrow: true,
                                isDisabled: (plantillas && plantillas?.meta?.total !== 0) && completedConfig(),
                                placement: "left",
                            },
                            button: {
                                label: "Probar plantilla",
                                isDisabled: (!plantillas || plantillas?.meta?.total === 0) || !completedConfig(),
                                onClick: () => {
                                    onOpen()

                                    if (showStep) setShowStep(false)
                                },
                                leftIcon: {
                                    as: HiOutlineDocumentMagnifyingGlass,
                                    boxSize: "20px",
                                },
                            },
                        }
                    ]}
                />


                <Flex
                    zIndex="99"
                >
                    {(showStep && (!user?.config?.plantillas && total)) &&
                        <OnboardingStep
                            position={{
                                right: "180px",
                                top: "65px",
                            }}
                            title="Consulta las distintas plantillas"
                            description="Explora las plantillas de correo disponibles para diversas situaciones y realiza el seguimiento de los envíos y recepciones de estos mails."
                            placement="top/right"
                            isDisabled={(!plantillas || plantillas?.meta?.total === 0) || !completedConfig()}
                            disabledTooltip={
                                !plantillas || plantillas?.meta?.total === 0
                                    ? "Debe tener plantillas creadas para poder probarlas"
                                    : "Debe completar toda la configuración para poder probar las plantillas"
                            }
                            onClick={() => {
                                setShowStep(false)
                                onOpen()

                            }}
                        />
                    }
                </Flex>

                <TestPlantillasModal
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </Flex>
        </Topbar>
    );
}