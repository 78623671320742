import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { addUsuario, updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { useState } from "react";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { UserInt } from "../../../../interfaces/UserInt";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable: () => void;
    updateGrupo?: (value: Record<string, string[]>) => void;
    grupo?: GruposInt;
}

export const NewTutorModal = ({ isOpen, onClose, setRefreshTable, updateGrupo = () => { }, grupo }: Props) => {
    const { user, refreshUser } = useAuthContex();
    const { total } = useUserPolicy();
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const toast = useToast();

    const initialValues = {
        nombre: null,
        apellidos: null,
        email: null,
        telefono: null,
        contratacion: null,
        dni: null,
        condicion: null
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        apellidos: Yup.string()
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        telefono: Yup.string()
            .notRequired(),
        contratacion: Yup.boolean()
            .required('Debe seleccionar el tipo de contratacion.')
            .typeError('Debe seleccionar el tipo de contratacion.'),
        condicion: Yup.number().when("contratacion", {
            is: false,
            then: (validationScheme) => validationScheme.notRequired(),
            otherwise: (validationScheme) => validationScheme
                .required('Debe ingresar un importe de condicion')
                .typeError('Debe ingresar un importe de condicion.')
                .max(100, 'El porcentaje de comisión no puede ser mayor al 100%'),

        }),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const newUsuario: any = {
            nombre: values.nombre,
            apellidos: values.apellidos,
            email: values.email,
            telefono: values.telefono,
            roleId: 2,
            tutorFreelance: values?.contratacion,
            porcentajeComision: values?.condicion
        }

        addUsuario({
            data: newUsuario,
            client: client
        })
            .then(async (response) => {
                const id = response?.data?.data?.id;

                if (!user?.config?.tutores && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                tutores: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    tutores: true
                                }
                            })
                        })
                }

                if (grupo) {
                    const tutores = grupo?.tutores ? [...grupo?.tutores?.map((t: UserInt) => t?.id), id] : [id];
                    updateGrupo({ tutores: tutores })
                }

                setRefreshTable();
                onClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `${newUsuario.nombre} se ha creado correctamente`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo tutor
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex direction="column" gap="10px" mb="30px">
                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="700"
                                                    mb="5px"
                                                >
                                                    Datos del tutor
                                                </Text>

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.nombre ? true : false}
                                                        name="nombre"
                                                        label="Nombre"
                                                        placeholder="Nombre del tutor"
                                                    />

                                                    <FormInput
                                                        isRequired={!values?.apellidos ? true : false}
                                                        name="apellidos"
                                                        label="Apellidos"
                                                        placeholder="Apellidos del tutor"
                                                    />
                                                </Flex>

                                                <FormInput
                                                    isRequired={!values?.email ? true : false}
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    placeholder="Email del tutor"
                                                />

                                                <Flex gap="15px">
                                                    <Box flex="1">
                                                        <FormInput name="telefono" label="Teléfono" placeholder="Teléfono del tutor" />
                                                    </Box>

                                                    <Box flex="1" />
                                                </Flex>
                                            </Flex>

                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="700"
                                                mb="15px"
                                            >
                                                Contratación
                                            </Text>

                                            <Flex
                                                gap="15px"
                                            >
                                                <Box flex="1">
                                                    <FormSelect
                                                        noHead={true}
                                                        name="contratacion"
                                                        placeholder="Seleccione una opcion"
                                                        options={[
                                                            { value: false, label: "Contratado" },
                                                            { value: true, label: "Colaborador" }
                                                        ]}
                                                        isRequired={values?.contratacion === null || values?.contratacion === undefined ? true : false}
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                            styleLabel: { fontSize: "18px", fontWeight: "700" }
                                                        }}
                                                    />
                                                </Box>

                                                <Box flex="1"
                                                    pos="relative"
                                                >
                                                    {values?.contratacion === true &&
                                                        <>
                                                            <FormInput
                                                                min={0}
                                                                step={0}
                                                                name="condicion"
                                                                type="number"
                                                                placeholder="% de comisión"
                                                                isRequired={values?.condicion ? false : true}

                                                            />

                                                            <Text
                                                                display={values?.condicion ? "block" : "none"}
                                                                pos="absolute"
                                                                top={"10px"}
                                                                right={"10px"}
                                                                fontSize="13px"
                                                                color="dark_grey"

                                                            >
                                                                % de comisión
                                                            </Text>
                                                        </>
                                                    }
                                                </Box>
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear tutor
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}