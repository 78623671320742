import { UserInt } from "../../../interfaces/UserInt"
import { Flex, Icon, Link, Progress, Text } from '@chakra-ui/react'
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { useState } from "react";
import { BiChevronUp } from "react-icons/bi";

interface Props {
    user: UserInt;
}

export const OnboardingCard = ({ user }: Props) => {
    const { handleGetInfo } = useTenantInfo();
    const produccion = handleGetInfo({ key: "produccion" });
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const obboardingList = [
        { title: "Personalización", complete: produccion, link: "/" },
        { title: "Crea tu primer Curso", complete: user?.config?.cursos, link: "/cursos" },
        { title: "Crea tu primer Grupo", complete: user?.config?.grupos, link: "/grupos" },
        { title: "Da de alta un Alumno", complete: user?.config?.alumnos, link: "/usuarios", },
        { title: "Da de alta un Tutor", complete: user?.config?.tutores, link: "/usuarios", },
        { title: "Crea tu primera Matrícula", complete: user?.config?.matriculas, link: "/grupos" },
        { title: "Revisa tu Empresa", complete: user?.config?.empresasOrganizadoras, link: "/configuracion/mis-empresas" },
        { title: "Revisa las Plantillas", complete: user?.config?.plantillas, link: "/plantillas" },
    ]

    const getProgress = (): number => {
        let count = 0;

        obboardingList?.map((item) => {
            if (item.complete) {
                count++;
            }
        })

        return count;
    }

    return (
        <Flex
            zIndex="1400"
            direction="column"
            pos="absolute"
            bottom="3%"
            right="2%"
            bg="secondary"
            rounded={"13px"}
            w="300px"
            shadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
            transition={"all 1s"}
            transform={isOpen ? "translate(0, 0)" : "translate(94%, 94%)"}
        >
            <Flex
                direction="column"
                py="16px"
                px="16px"
                gap="5x"
            >
                <Flex
                    alignItems="center"
                    gap="10px"
                >
                    <Icon
                        as={BiChevronUp}
                        boxSize="25px"
                        color="purewhite"
                        cursor="pointer"
                        _active={{ transform: "scale(0.85)" }}
                        onClick={() => setIsOpen((prev) => !prev)}
                        transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                        transition={"all 0.3s"}
                    />

                    <Text
                        fontSize="18px"
                        fontWeight="600"
                        lineHeight="29.615px"
                        color="purewhite"
                    >
                        Configuración
                    </Text>
                </Flex>

                <Flex
                    alignItems="center"
                    gap="13px"
                    ml="35px"
                >
                    <Text
                        fontSize="16px"
                        color="purewhite"
                        fontWeight="600"
                        lineHeight="24.231px"
                    >
                        {getProgress()}/{obboardingList?.length}
                    </Text>

                    <Progress
                        value={getProgress()}
                        max={obboardingList?.length}
                        w="100%"
                        rounded="50px"
                        h="14px"
                        sx={{ '& > div': { background: "main" } }}
                    />
                </Flex>
            </Flex>

            <Flex
                bg="purewhite"
                w="100%"
                rounded="13px"
                px="16px"
                py="16px"
                direction="column"
                gap="16px"
            >
                {obboardingList?.map((item, index) => (
                    <Flex
                        key={index}
                        alignItems="center"
                        gap="10px"
                    >
                        {item?.complete
                            ? <Icon as={BsCheckCircleFill} boxSize="22px" color="success" />
                            : <Icon as={BsCircle} boxSize="22px" color="#6474EF" />
                        }


                        {item?.complete
                            ?
                            <Text
                                fontSize="16px"
                                color="pureblack"
                                fontWeight="400"
                                lineHeight="24.615px"
                            >
                                {item.title}
                            </Text>
                            :
                            <Link
                                _hover={{}}
                                cursor={"pointer"}
                                as={ReactRouterLink}
                                to={item?.link}
                                fontSize="16px"
                                color="pureblack"
                                fontWeight="400"
                                lineHeight="24.615px"
                            >
                                {item.title}
                            </Link>
                        }
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}