import { Box, Flex, Icon, Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { LeccionInt } from "../../../interfaces/CursosInt"
import { BsArrowsFullscreen } from "react-icons/bs";

interface Props {
    leccion: LeccionInt;
    isModal?: boolean;
}

const transformUrlSlides = (url: string | null | undefined): string => {
    if (!url) return ""

    if (url?.includes('slides')) {
        return url.replace("/embed", "/fullscreen")
    }

    return url;
}

export const EmbebidoViewer = ({ isModal, leccion }: Props) => {
    const { onOpen: onOpenFullScreen, onClose: onCloseFullScreen, isOpen: isOpenFullScreen } = useDisclosure();

    return (
        <Flex
            py="20px"
            px="20px"
            w="100%"
        >
            <Flex
                direction="column"
                w="100%"
                fontSize="15px"
                whiteSpace="pre-line"
            >
                <iframe
                    width="100%"
                    height={!isModal ? "420px" : "500px"}
                    allowFullScreen
                    src={transformUrlSlides(leccion?.contenido)}
                />

                <Flex
                    bg="#444444"
                    w="100%"
                    justifyContent="end"
                    py="5px"
                    px="10px"
                    rounded="4px"
                >
                    <Icon
                        as={BsArrowsFullscreen}
                        boxSize="20px"
                        mr="10px"
                        color="purewhite"
                        cursor="pointer"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            onOpenFullScreen();
                        }}
                    />
                </Flex>
            </Flex>

            <ModalFullScreen
                isOpen={isOpenFullScreen}
                onClose={onCloseFullScreen}
                leccion={leccion}
            />
        </Flex >
    )
}


interface PropsModal {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | null | undefined;
}

const ModalFullScreen = ({ isOpen, onClose, leccion }: PropsModal) => {
    const totalHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    return (
        <Modal
            size="full"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <Flex
                    w="100%"
                    direction="column"
                    justifyContent="center"
                    gap="5px"
                    alignItems="center"
                >
                    <iframe
                        width="100%"
                        height={totalHeight - 35}
                        src={transformUrlSlides(leccion?.contenido)}
                        style={{ overflow: 'hidden', border: 'none' }}
                    />

                    <Flex
                        bg="#444444"
                        w="100%"
                        justifyContent="end"
                        py="5px"
                        px="10px"
                        rounded="4px"
                    >
                        <Icon
                            as={BsArrowsFullscreen}
                            boxSize="20px"
                            mr="10px"
                            color="purewhite"
                            cursor="pointer"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                onClose();
                            }}
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    )
}