import { useEffect, useRef, useState } from "react";
import { LeccionInt } from "../../../../../interfaces/CursosInt";
import { ComponenteLeccionEnum, LeccionTipoEnum } from "../../../../../shared/utils/Types/LeccionTipoEnum";
import { categorizarLeccion } from "../../../../../shared/utils/functions/categorizarLeccion";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { PDFViewer } from "../../../../../shared/components/PreviewComponents/PDFViewer";
import { ImagenViewer } from "../../../../../shared/components/PreviewComponents/Imagen";
import { EmbebidoViewer } from "../../../../../shared/components/PreviewComponents/Embebido";
import { VideoViewer } from "../../../../../shared/components/PreviewComponents/VideoViewer";

interface Props {
    leccion: LeccionInt | undefined;
    isModal?: boolean;
}

export const PreviewLeccion = ({ leccion, isModal = false }: Props) => {
    const [componentType, setComponentType] = useState<ComponenteLeccionEnum>()

    useEffect(() => {
        const componente = categorizarLeccion(leccion)
        setComponentType(componente)
    }, [leccion])

    return (
        <>
            {(componentType === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        py="20px"
                        px="20px"
                    >
                        <PDFViewer
                            url={leccion?.contenido}
                        />
                    </Flex>
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <VideoViewer
                        leccion={leccion}
                    />
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <EmbebidoViewer
                        isModal={isModal}
                        leccion={leccion}
                    />
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <ImagenViewer
                        leccion={leccion}
                        isModal={isModal}
                    />
                </InformationBox>
            }
            {/* 
            {(componentType === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        w="100%"
                        py="20px"
                        px="20px"
                    >
                        <Flex
                            w="100%"
                            alignItems={"start"}
                            justifyContent={"center"}
                            overflow="auto"
                            className="scroll-bar"
                            h="auto"
                            maxH={!isModal ? "700px" : "550px"}
                        >
                            <MarkdownPreview
                                source={leccion?.contenido || ''}
                                style={{
                                    padding: "20px",
                                    fontSize: '15px',
                                    width: '100%',
                                    maxWidth: '100%',
                                    backgroundColor: "transparent"
                                }}
                                wrapperElement={{
                                    "data-color-mode": "light"
                                }}
                            />
                        </Flex>
                    </Flex>
                </InformationBox>
            } */}

            {(componentType === ComponenteLeccionEnum.LINK && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        py="20px"
                        px="20px"
                        direction="column"
                        gap="20px"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="500"
                            color="pureblack"
                        >
                            {leccion?.tipo === LeccionTipoEnum?.ZOOM ?
                                "Haz click en el siguiente botón para ingresar a la reunión"
                                :
                                "Haz click en el siguiente botón para acceder al recurso"
                            }
                        </Text>

                        <Button
                            px="10px"
                            py="10px"
                            h="50px"
                            w="30%"
                            gap="10px"
                            rounded="5px"
                            border="1px solid"
                            borderColor={"secondary"}
                            fontSize="14px"
                            fontWeight={"500"}
                            color="purewhite"
                            bg="secondary"
                            _hover={{ bg: "variant" }}
                            isDisabled={!leccion?.contenido || leccion?.contenido === ""}
                            onClick={() => {
                                if (leccion?.contenido)
                                    window.open(leccion?.contenido, "_blank")
                            }}
                        >
                            {leccion?.tipo === LeccionTipoEnum.ZOOM ? "Ingresar en la reunión" : "Ir al recurso"}
                        </Button>
                    </Flex>
                </InformationBox>
            }
        </>
    )
}