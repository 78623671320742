import { Flex, Image, Text } from "@chakra-ui/react"
import contenido from "../../../../assets/img/contenido.png"

export const NoSelectContenido = () => {
    return (
        <Flex
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            gap="100px"
        >
            <Flex
                direction="column"
                gap="20px"
            >
                <Text
                    color="pureblack"
                    fontSize="32px"
                    fontWeight="600"
                >
                    Añade contenido a tu curso
                </Text>

                <Flex
                    direction="column"
                    color="#616675"
                    fontSize="18px"
                    fontWeight="400"
                >
                    <Text>
                        Cree módulos o lecciones nuevas, o actualice los existentes.
                    </Text>

                    <Text>
                        Estás en el camino correcto.
                    </Text>
                </Flex>
            </Flex>

            <Image
                src={contenido}
                alt="Contenido"
                boxSize="350px"
            />
        </Flex>
    )
}