import { Box, Button, Flex, FormLabel, useToast } from "@chakra-ui/react";
import { CursosInt } from "../../../../../interfaces/CursosInt";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { updateCurso } from "../../../../../shared/middlewares/cursos.middleware";
import { useClient } from "../../../../../shared/context/client.context";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import ReactQuill from "react-quill";
import { SkeletonGuiaDidactica } from "../../../components/Skeletons/CursosGuiaDidacticaSkeleton";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";

interface Props {
    curso: CursosInt | undefined;
    loadingCurso: boolean
    Refresh: () => void;
}

export const TabGuiaDidactica = ({ curso, Refresh, loadingCurso }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => { }, [curso])

    const getInfoGuia = (type: "datos" | "dirigido" | "requisitos" | "objetivos") => {
        if (!curso?.infoGuia) return undefined;

        if (Reflect.has(curso?.infoGuia, type)) return curso?.infoGuia[type]
        else return undefined;
    }

    const initialValues = {
        datos: getInfoGuia("datos"),
        dirigido: getInfoGuia("dirigido"),
        requisitos: getInfoGuia("requisitos"),
        objetivos: getInfoGuia("objetivos"),
        codigoGrupoAcccion: curso?.codigoGrupoAcccion
    };

    const validationSchema = Yup.object({
        datos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        dirigido: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        requisitos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        objetivos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        codigoGrupoAcccion: Yup.string()
            .required("El código de grupo de acción es requerido")
            .typeError("El código de grupo de acción debe ser una cadena de texto")
    });

    const handleSubmit = (values: any) => {
        if (!curso?.id) return;
        setIsLoading(true)

        const guiaDidactica = {
            datos: values?.datos,
            dirigido: values?.dirigido,
            requisitos: values?.requisitos,
            objetivos: values?.objetivos,
        }

        updateCurso({
            id: curso?.id,
            data: {
                infoGuia: guiaDidactica
            },
            client: client
        })
            .then(() => {
                Refresh();
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Guía didáctica actualizada correctamente")
            })
            .catch(() => { })
            .finally(() => setIsLoading(false))
    }

    return (

        loadingCurso ?
            <SkeletonGuiaDidactica />
            :
            <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex direction="column" gap="0px" className='information-texteditor'>
                                <Flex gap="20px">
                                    <Flex
                                        flex="1"
                                        direction="column"
                                    >
                                        <InformationBox
                                            noHeader
                                        >
                                            <Box p="20px">

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Codigo de Grupo Accion
                                                    </FormLabel>

                                                    <FormInput
                                                        name="codigoGrupoAcccion"
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Datos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.datos}
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, datos: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Requisitos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.requisitos}
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, requisitos: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>
                                            </Box>
                                        </InformationBox>
                                    </Flex>

                                    <Flex
                                        flex="1"
                                        direction="column"
                                    >
                                        <InformationBox
                                            noHeader
                                        >
                                            <Box
                                                p="20px"
                                            >
                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        ¿A quién va dirigido?
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.dirigido}
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, dirigido: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Objetivos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        value={values.objetivos}
                                                        theme="snow"
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, objetivos: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>
                                            </Box>
                                        </InformationBox>
                                    </Flex>
                                </Flex>

                                <Flex
                                    w="100%"
                                    justifyContent="center"
                                >
                                    <Button
                                        isLoading={isLoading}
                                        type="submit"
                                        w="fit-content"
                                        h="fit-content"
                                        bg="black"
                                        color="#FFF"
                                        p="10px 20px"
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        _hover={{ bg: "light_grey", color: "black" }}
                                    >
                                        Guardar
                                    </Button>
                                </Flex>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
    )
}


const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],

    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ align: [] }],

    ["code-block"],
    ["image"],
];

const modules = {
    toolbar: toolbarOptions,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const formats = [
    "header",
    "image",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
];