import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum"
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum"
import { useAuthContex } from "../../../../shared/context/auth.context"
import { CustomButton } from "../../../../shared/components/CustomElements/CustomButton"
import { BiPlusCircle } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { useData } from "../../../../shared/hooks/useData"
import { useClient } from "../../../../shared/context/client.context"
import { NewExamenModal } from "../../components/Modals/NewExamenModal"
import { Table } from "../../../../shared/components/Table/Table"
import { Filters } from "../../../../shared/components/Filters/Filters"
import { ExamenesColumns } from "./Columns/ExamenesColumns"
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession"

export const ExamenesTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.EXAMENES });
    const { user } = useAuthContex()
    const client = useClient()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: examenes, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.EXAMENES,
        query: query
    });

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Exámenes
                </Text>
            }
        >
            <Flex
                p="30px"
                direction="column"
                gap="20px"
                w="100%"
            >
                <Filters
                    query={query}
                    setQuery={setQuery}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE,
                            type: FiltrosTypes.INPUT,
                        }
                    ]}
                    showBody={false}
                    button={
                        isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                            <CustomButton
                                button={{
                                    label: "Nuevo exámen",
                                    onClick: onOpen,
                                    isDisabled: false,
                                    leftIcon: {
                                        as: BiPlusCircle,
                                        boxSize: "20px"
                                    }
                                }}
                                tooltip={{
                                    label: "Crear nuevo examen",
                                    isDisabled: false
                                }}
                            />
                            : undefined
                    }
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={examenes?.data || []}
                    columns={ExamenesColumns()}
                    isLoading={loading}
                    total={examenes?.meta?.total || 0}
                />

                <NewExamenModal
                    isOpen={isOpen}
                    onClose={onClose}
                    Refresh={Refresh}
                />
            </Flex>
        </Topbar>
    )
}