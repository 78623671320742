import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { MatriculasTable } from "./views/Table/MatriculasTable";
import { TabsInformationMatriculas } from "./views/Information/TabsInformation";

export const Matriculas = () => {
    
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <MatriculasTable />
                        }
                    />

                    <Route
                        path='/:id/*'
                        element={
                            <TabsInformationMatriculas />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}