import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton, Image, FormLabel, Checkbox } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { addEmpresa } from "../../../shared/middlewares/empresas.middleware";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { useState } from "react";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../shared/context/client.context";
import { EmpresasInt } from "../../../interfaces/EmpresasInt";
import { FilePond } from "react-filepond";
import { defaultIcon } from "../../../shared/components/Icons/IconBox";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable?: () => void;
    selectEmpresaGrupo?: (empresa: any) => void;
    interna?: boolean;
}
export const NewEmpresaModal = ({
    isOpen,
    onClose,
    setRefreshTable = () => { },
    selectEmpresaGrupo = (empresa: any) => { },
    interna = false
}: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const toast = useToast();

    const initialValues = {
        interna: interna,

        nombre: null,
        cif: null,
        email: null,
        telefono: null,

        // direccion: null,
        // codigoPostal: null,
        // localidad: null,
        // responsable: null,
        // telefonoContacto: null,
        // inicioTramo1: null,
        // finTramo1: null,
        // inicioTramo2: null,
        // finTramo2: null,
        // dias: {
        //     L: false,
        //     M: false,
        //     X: false,
        //     J: false,
        //     V: false,
        //     S: false,
        //     D: false,
        // },

        icono: null as any,
        // imagen: null as any,
    };

    const validationSchema = Yup.object({
        interna: Yup.boolean(),
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        cif: Yup.string()
            .required('El cif es obligatorio.')
            .typeError('El cif es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        telefono: Yup.string()
            .notRequired(),
        icono: Yup.mixed()
            .notRequired()
            .nullable(),
        // imagen: Yup.mixed()
        //     .notRequired()
        //     .nullable(),

        // direccion: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('La dirección es obligatoria.')
        //         .typeError('La dirección es obligatoria.'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // codigoPostal: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El Codigo Postal es obligatorio.')
        //         .typeError('El Codigo Postal es obligatorio.'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // localidad: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('La localidad es obligatora.')
        //         .typeError('La localidad es obligatoria.'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // responsable: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El nombre del responsable es obligatorio.')
        //         .typeError('El nombre del responsable es obligatorio.'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // telefonoContacto: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El teléfono del responsable es obligatorio')
        //         .typeError('El teléfono del responsable es obligatorio'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // inicioTramo1: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El Inicio del primer Tramo es obligatorio')
        //         .typeError('El Inicio del primer Tramo es obligatorio'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // finTramo1: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El Fin del primer Tramo es obligatorio')
        //         .typeError('El Fin del primer Tramo es obligatorio'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // inicioTramo2: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El Inicio del segundo Tramo es obligatorio')
        //         .typeError('El Inicio del segundo Tramo es obligatorio'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // finTramo2: Yup.string().when('interna', {
        //     is: true,
        //     then: (schema) => schema
        //         .required('El Fin del segundo Tramo es obligatorio')
        //         .typeError('El Fin del segundo Tramo es obligatorio'),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
        // dias: Yup.object().when('interna', {
        //     is: true,
        //     then: (schema) => schema.shape({
        //         L: Yup.boolean(),
        //         M: Yup.boolean(),
        //         X: Yup.boolean(),
        //         J: Yup.boolean(),
        //         V: Yup.boolean(),
        //         S: Yup.boolean(),
        //         D: Yup.boolean(),
        //     }).test(
        //         'al-menos-un-dia',
        //         'Debe seleccionar al menos un día',
        //         (value) => Object.values(value || {}).some(Boolean)
        //     ),
        //     otherwise: (schema) => schema
        //         .notRequired()
        //         .nullable()
        // }),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        let newEmpresa: EmpresasInt = {
            nombre: values.nombre,
            cif: values.cif,
            email: values.email,
            telefono: values.telefono,
        }

        if (values.interna) {
            // const dias: string = Object.entries(values.dias)
            //     .filter(([_, value]) => value)
            //     .map(([key, _]) => key)
            //     .join(' ');

            newEmpresa = {
                ...newEmpresa,
                interna: true,
                // extraInfo: {
                //     direccion: values.direccion,
                //     codigoPostal: values?.codigoPostal,
                //     localidad: values?.localidad,
                //     responsable: values?.responsable,
                //     telefonoContacto: values?.telefonoContacto,
                //     horario: {
                //         inicioTramo1: values?.inicioTramo1,
                //         finTramo1: values?.finTramo1,
                //         inicioTramo2: values?.inicioTramo2,
                //         finTramo2: values?.finTramo2,
                //         dias: dias,
                //     }
                // },
                icono: (values?.icono && values?.icono?.length > 0) ? values?.icono[0]?.file : null,
                // imagen: (values?.imagen && values?.imagen?.length > 0) ? values?.imagen[0]?.file : null,
            }
        }

        addEmpresa({
            data: newEmpresa,
            client: client
        })
            .then((response: any) => {
                selectEmpresaGrupo(response?.data?.data);
                setRefreshTable();
                onClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado la empresa ${newEmpresa.nombre}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const handleShowFile = (file: File) => {
        if (file) {
            const portada = URL.createObjectURL(file)

            return portada
        }
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            {interna ? "Nueva empresa interna" : "Nueva empresa"}
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="700"
                                                mb="15px"
                                            >
                                                Datos de la empresa
                                            </Text>

                                            <Flex gap="20px" mb={interna ? "0" : "15px"}>
                                                <FormInput
                                                    name="nombre"
                                                    label="Nombre empresa"
                                                    placeholder="Nombre empresa"
                                                    isRequired={!values?.nombre ? true : false}
                                                />

                                                <FormInput
                                                    name="cif"
                                                    label="CIF"
                                                    placeholder="CIF de la empresa"
                                                    isRequired={!values?.cif ? true : false}
                                                />
                                            </Flex>

                                            {interna &&
                                                <Flex gap="20px">
                                                    <FormInput
                                                        name="email"
                                                        label="Email"
                                                        type="email"
                                                        placeholder="Email de la empresa"
                                                        isRequired={!values?.email ? true : false}
                                                    />

                                                    <FormInput name="telefono" label="Teléfono" placeholder="Teléfono de la empresa" />
                                                </Flex>
                                            }

                                            {!interna ?
                                                <>
                                                    <Text
                                                        color="black"
                                                        fontSize="18px"
                                                        fontWeight="700"
                                                        mt="30px"
                                                        mb="15px"
                                                    >
                                                        Datos de contacto
                                                    </Text>

                                                    <FormInput
                                                        name="email"
                                                        label="Email"
                                                        type="email"
                                                        placeholder="Email de la empresa"
                                                        isRequired={!values?.email ? true : false}
                                                    />

                                                    <FormInput name="telefono" label="Teléfono" placeholder="Teléfono de la empresa" />
                                                </>
                                                :
                                                <>
                                                    {/* <Text
                                                        color="black"
                                                        fontSize="18px"
                                                        fontWeight="700"
                                                        mt="30px"
                                                        mb="15px"
                                                    >
                                                        Datos adicionales
                                                    </Text>

                                                    <FormInput
                                                        name="direccion"
                                                        label="Dirección"
                                                        isRequired={!values?.direccion ? true : false}
                                                    />

                                                    <Flex gap="20px">
                                                        <FormInput
                                                            name="codigoPostal"
                                                            label="Codigo Postal"
                                                            isRequired={!values?.codigoPostal ? true : false}
                                                        />

                                                        <FormInput
                                                            name="localidad"
                                                            label="Localidad"
                                                            isRequired={!values?.localidad ? true : false}
                                                        />
                                                    </Flex>

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        <Text
                                                            fontSize="12px"
                                                            fontWeight="600"
                                                            color="font"
                                                            textTransform="uppercase"
                                                            mb="5px"
                                                        >
                                                            Responsable
                                                        </Text>

                                                        <Flex gap="20px">
                                                            <FormInput
                                                                name="responsable"
                                                                label="Nombre"
                                                                isRequired={!values?.responsable ? true : false}
                                                            />

                                                            <FormInput
                                                                name="telefonoContacto"
                                                                label="Teléfono"
                                                                isRequired={!values?.telefonoContacto ? true : false}
                                                            />
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        <Text
                                                            fontSize="12px"
                                                            fontWeight="600"
                                                            color="font"
                                                            textTransform="uppercase"
                                                            mb="5px"
                                                        >
                                                            Horario
                                                        </Text>

                                                        <Flex gap="20px">
                                                            <FormInput
                                                                name="inicioTramo1"
                                                                label="Inicio Primer Tramo"
                                                                isRequired={!values?.inicioTramo1 ? true : false}
                                                            />

                                                            <FormInput
                                                                name="finTramo1"
                                                                label="Fin Primer Tramo"
                                                                isRequired={!values?.finTramo1 ? true : false}
                                                            />
                                                        </Flex>


                                                        <Flex gap="20px">
                                                            <FormInput
                                                                name="inicioTramo2"
                                                                label="Inicio Segundo Tramo"
                                                                isRequired={!values?.inicioTramo2 ? true : false}
                                                            />

                                                            <FormInput
                                                                name="finTramo2"
                                                                label="Fin Segundo Tramo"
                                                                isRequired={!values?.finTramo2 ? true : false}
                                                            />
                                                        </Flex>

                                                        <Flex
                                                            mt="5px"
                                                            mb="20px"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            {["L", "M", "X", "J", "V", "S", "D"].map((dia) => (
                                                                <Checkbox
                                                                    key={dia}
                                                                    gap="10px"
                                                                    flexDirection="row-reverse"
                                                                    fontSize="12px"
                                                                    fontWeight="600"
                                                                    color="font"
                                                                    name={`dias.${dia}`}
                                                                    isChecked={(values?.dias as any)[dia]}
                                                                    onChange={() => {
                                                                        formik.setFieldValue(`dias.${dia}`, !(values?.dias as any)[dia]);
                                                                    }}
                                                                >
                                                                    {dia}
                                                                </Checkbox>
                                                            ))}
                                                        </Flex>
                                                    </Flex> */}

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        <FormLabel
                                                            display="flex"
                                                            gap="3px"
                                                            color="font"
                                                            fontSize="14px"
                                                            fontWeight="400"
                                                        >
                                                            Icono de la empresa
                                                        </FormLabel>

                                                        <Flex
                                                            gap="20px"
                                                            alignItems="start"
                                                        >
                                                            <Flex
                                                                w="120px"
                                                                rounded="10px"
                                                                bg="purewhite"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                p="10px"
                                                            >
                                                                {values?.icono && values?.icono?.length > 0 ?
                                                                    <Image
                                                                        src={handleShowFile(values?.icono[0]?.file)}
                                                                        m="auto"
                                                                        boxSize="55px"
                                                                        rounded="10px"
                                                                    />
                                                                    :
                                                                    <Image
                                                                        boxSize="55px"
                                                                        rounded="10px"
                                                                        src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                                                    />
                                                                }
                                                            </Flex>


                                                            <Box
                                                                pos={"relative"}
                                                                w="100%"
                                                            >
                                                                <FilePond
                                                                    name='icono'
                                                                    files={values?.icono || undefined}
                                                                    allowReorder={true}
                                                                    onupdatefiles={(e) => {
                                                                        if (e.length > 0) {
                                                                            formik.setFieldValue("icono", e)
                                                                        } else {
                                                                            formik.setFieldValue("icono", null)
                                                                        }
                                                                    }}
                                                                    allowImagePreview={false}
                                                                    labelIdle='Adjuntar o deja caer tu archivo aquí'
                                                                    credits={false}
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    </Flex>

                                                    {/* <Flex
                                                        direction="column"
                                                        mt="20px"
                                                    >
                                                        <FormLabel
                                                            display="flex"
                                                            gap="3px"
                                                            color="font"
                                                            fontSize="14px"
                                                            fontWeight="400"
                                                        >
                                                            Firma de la empresa
                                                        </FormLabel>

                                                        <Flex
                                                            gap="20px"
                                                            alignItems="start"
                                                        >
                                                            {values?.imagen && values?.imagen?.length > 0 &&
                                                                <Flex
                                                                    w="35%"
                                                                    rounded="10px"
                                                                    bg="purewhite"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    p="10px"
                                                                >
                                                                    <Image
                                                                        src={handleShowFile(values?.imagen[0]?.file)}
                                                                        h="55px"
                                                                        rounded="10px"
                                                                    />
                                                                </Flex>
                                                            }


                                                            <Box
                                                                pos={"relative"}
                                                                w={values?.imagen && values?.imagen?.length > 0 ? "65%" : "100%"}
                                                            >
                                                                <FilePond
                                                                    name='imagen'
                                                                    files={values?.imagen || undefined}
                                                                    allowReorder={true}
                                                                    onupdatefiles={(e) => {
                                                                        if (e.length > 0) {
                                                                            formik.setFieldValue("imagen", e)
                                                                        } else {
                                                                            formik.setFieldValue("imagen", null)
                                                                        }
                                                                    }}
                                                                    allowImagePreview={false}
                                                                    labelIdle='Adjuntar o deja caer tu archivo aquí'
                                                                    credits={false}
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    </Flex> */}
                                                </>
                                            }

                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear empresa
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent >
            </Modal >

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}