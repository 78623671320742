import { Flex, Skeleton, Text } from "@chakra-ui/react";
import { BiBookReader, BiBriefcase, BiBuilding, BiIdCard } from "react-icons/bi";
import { AlumnosTable } from "./Alumno/Table/Table";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum, UserSubTypeEnum } from "../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../shared/context/auth.context";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { useUserPolicy } from "../../../shared/hooks/PermissionPolicy";
import { useModuloFundae } from "../../../shared/hooks/ModuloFundae";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { TutoresTable } from "./Tutor/Table/Table";
import { SupervisoresTable } from "./Supervisor/Table/Table";
import { InspectoresTable } from "./Inspector/Table/Table";
import { AdministradoresTable } from "./Administrador/Table/Table";
import { RiAdminLine } from "react-icons/ri";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { useLocation } from "react-router-dom";

export const TabsTableUsuarios = () => {
    const moduloFUNDAE = useModuloFundae();
    const { user } = useAuthContex();
    const { contenido, total } = useUserPolicy();
    const location = useLocation();

    const checkConfig = () => {
        if (user?.extraData?.subType === UserSubTypeEnum.TOTAL) {
            if (
                user?.config &&
                user?.config?.cursos &&
                user?.config?.plantillas &&
                user?.config?.grupos &&
                user?.config?.matriculas &&
                user?.config?.alumnos &&
                user?.config?.tutores
            ) return true
            else return false
        }
        else return true
    }

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Alumnos",
            path: "alumnos",
            icon: BiBookReader,
            content: <AlumnosTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Tutores",
            path: "tutores",
            icon: BiBriefcase,
            content: <TutoresTable />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido),
            label: "Supervisores Empresa",
            path: "supervisores",
            icon: BiBuilding,
            content: <SupervisoresTable />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !contenido && moduloFUNDAE),
            label: "Inspectores",
            path: "inspectores",
            icon: BiIdCard,
            content: <InspectoresTable />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && total && checkConfig()),
            label: "Administradores",
            path: "administradores",
            icon: RiAdminLine,
            content: <AdministradoresTable />
        },
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    {
                        location?.pathname?.includes("alumnos") ? "Alumnos"
                            : location?.pathname?.includes("tutores") ? "Tutores"
                                : location?.pathname?.includes("supervisores") ? "Supervisores"
                                    : location?.pathname?.includes("inspectores") ? "Inspectores"
                                        : location?.pathname?.includes("administradores") ? "Administradores"
                                            : "Usuarios"
                    }
                </Text>
            }
        >
            <Flex
                direction="column"
                p="30px"
            >
                <TabsRouter
                    tabs={tabs}
                />
            </Flex>
        </Topbar>
    );
};
