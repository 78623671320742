import {
  Box,
  Flex,
  Icon,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { AxiosResponse } from "axios";
import { getTutores } from "../../../shared/middlewares/tutores.middleware";
import { useClient } from "../../../shared/context/client.context";
import { HiOutlineFire } from "react-icons/hi";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

interface topProfesoresProps {
  colors?: any;
}


export default function TopProfesores({ colors }: topProfesoresProps) {
  const client = useClient();
  const [profesores, setProfesores] = useState<any[]>([]);

  useEffect(() => {
    if (!client) return
    const query = "?sortBy=totalMatriculas&order=desc&limit=4";

    getTutores({
      client: client,
      query: query
    }).then((response: AxiosResponse) => {
      const stats = response?.data?.data?.data;
      // const totalProximasMatriculas = stats.profesor?.meta?.totalProximasMatriculas
      setProfesores(stats);

    });
  }, [client]);

  const statsProfesores = (function () {
    const statsMeta: any = []
    profesores?.map((profesor: any) => {
      const metaTotalMatriculas = parseInt(profesor?.meta?.totalMatriculas)
      statsMeta.push({ totalMatriculas: metaTotalMatriculas })
    })
    return statsMeta
  })()

  return (
    <Flex
      direction="column"
      flex="1"

      overflowX={{ sm: "scroll", lg: "hidden" }}
      bg="purewhite"
      p="24px"
      borderWidth="1px"
      borderColor="#DFE2E6"
      rounded="14px"
      boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
      gap="48px"

    >

      <Flex
        align="center"
        justify="start"
        gap="12px"
      >
        <Flex
          align="center"
          justify="center"
          bgColor="#F9FAFB"
          borderRadius="4px"
          boxSize="28px"
        >
          <Icon
            as={HiOutlineFire}
            color="#616675"
            boxSize="18px"
          />
        </Flex>
        <Text
          fontSize="18px"
          fontWeight="400"
          color="pureblack"
        >
          Top docentes con matrículas
        </Text>
      </Flex>

      <Flex
        justify="center"
        flexWrap="wrap"
        gap="40px"
      >
        {profesores?.map((profesor: any, index: number) => (
          <Flex
            key={index}
            direction="column"
          >
            <Flex
              gap="8px"
              align="center"
            >
              <Box
                borderWidth="2px"
                borderRadius="50px"
                h="17px"
                borderColor={index === 0 ? "secondary" : index === 1 ? "main" : index === 2 ? "#DFE2E6" : "#67A8FF"}
              />
              <Text
                fontWeight="400"
                fontSize="16px"
                color="#616675"
              >
                {profesor?.nombre}
              </Text>
            </Flex>
            <Text
              fontWeight="500"
              fontSize="23px"
              color="pureblack"
              w="100%"
              textAlign="center"
            >
              {profesor?.meta?.totalMatriculas}
            </Text>
          </Flex>
        ))}
      </Flex>

      <Flex
        flex="1"
        align="center"
        justify="center"
      >
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <PieChart
          >
            <Pie
              data={statsProfesores}
              dataKey="totalMatriculas"
              cx="50%" cy="50%"
              outerRadius="100%"
              innerRadius="60%"
              startAngle={360}
              endAngle={0}
              paddingAngle={2.5}
              cornerRadius={5}
            >
              {statsProfesores.map((stats: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === 0 ? colors?.variant : index === 1 ? colors?.main : index === 2 ? "#DFE2E6" : "#67A8FF"}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
}
