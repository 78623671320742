import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { GruposInt } from "../../../../../interfaces/GruposInt";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { useClient } from "../../../../../shared/context/client.context";
import { MatriculasInt } from "../../../../../interfaces/MatriculasInt";
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete";
import { bajaMatricula, deleteMatricula } from "../../../../../shared/middlewares/matriculas.middlewate";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { ModalBajaMatricula } from "../../../../../shared/components/Modals/ModalBajaMatricula";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
import { Table } from "../../../../../shared/components/Table/Table";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { MatriculasBajaColumns } from "../../../components/Columns/MatriculasBajaColumns";
import { MatriculasColumns } from "../../../components/Columns/MatriculasColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

interface Props {
    hasMatriculasDeBaja: boolean;
    refreshGrupo: () => void;
    refreshMatriculas: boolean;
    setRefreshMatriculas: Dispatch<SetStateAction<boolean>>;
}

export const TabMatriculas = ({
    hasMatriculasDeBaja,
    refreshGrupo,
    refreshMatriculas,
    setRefreshMatriculas
}: Props) => {
    const { id } = useParams();
    const { contenido } = useUserPolicy();
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MATRICULAS_GRUPO });
    const toast = useToast();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { isOpen: isOpenBajaMatricula, onOpen: onOpenBajaMatricula, onClose: onCloseBajaMatricula } = useDisclosure();

    const matriculaRef = useRef<MatriculasInt | null>(null)

    const { data: matriculas, loading, Refresh } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "false",
        },
        query: query
    })

    const { data: matriculasBaja, loading: loadingBaja } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "true",
        },
        ignoreRequest: !hasMatriculasDeBaja
    })

    useEffect(() => {
        const refreshData = async () => {
            if (refreshMatriculas) {
                await Refresh();
                setRefreshMatriculas(false);
            }
        };

        refreshData();
    }, [refreshMatriculas]);

    const onEventRemove = (event: MatriculasInt) => {
        matriculaRef.current = event

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onEventBajaMatricula = (event: MatriculasInt) => {
        matriculaRef.current = event

        if (isOpenDelete) onCloseBajaMatricula();
        onOpenBajaMatricula();
    };

    const onDelete = () => {
        deleteMatricula({ matriculaId: matriculaRef?.current?.id, client: client })
            .then(() => {
                Refresh()
                refreshGrupo()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "La matrícula se ha eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const onConfirm = () => {
        bajaMatricula({ id: matriculaRef?.current?.id, client: client })
            .then(() => {
                Refresh()
                refreshGrupo()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "La matrícula se ha dado de baja correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseBajaMatricula())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!matriculaRef.current?.user?.nombre || !matriculaRef.current?.user?.apellidos) return defaultWord

        const keyword = matriculaRef.current?.user?.nombre + " " + matriculaRef.current?.user?.apellidos

        return keyword
    }

    return (
        <Flex direction="column" gap="20px" mt="20px">
            <>
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    showBody={false}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={matriculas?.data || []}
                    columns={MatriculasColumns({ onEventRemove, onEventBajaMatricula, contenido })}
                    isLoading={loading}
                    total={matriculas?.meta?.total || 0}
                />

                {hasMatriculasDeBaja &&
                    <Flex
                        direction="column"
                        w="100%"
                        gap="10px"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="600"
                            color="font"
                        >
                            Matriculas de baja
                        </Text>

                        <Table
                            query={query}
                            setQuery={setQuery}
                            data={matriculasBaja?.data || []}
                            columns={MatriculasBajaColumns()}
                            isLoading={loadingBaja}
                            showFooter={false}
                        />
                    </Flex>
                }
            </>

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("Eliminar matrícula")}
            />

            <ModalBajaMatricula
                isOpen={isOpenBajaMatricula}
                onClose={onCloseBajaMatricula}
                onConfirm={onConfirm}
                matricula={matriculaRef.current}
                label={getKeyWord("Dar de baja matrícula")}
            />
        </Flex>
    )
}