import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet"
import { TabsEmails } from "./views/TabsEmails"

export const Emails = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Emails | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <TabsEmails
                            />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}