export const prefixCountryPhone: Record<string, string> = {
    "es-ES": "+34",
    "en-US": "+1",
    "fr-FR": "+33",
    "de-DE": "+49",
    "it-IT": "+39",
    "pt-PT": "+351",
    "pt-BR": "+55",
    "zh-CN": "+86",
    "ja-JP": "+81",
    "ru-RU": "+7",
    "in-IN": "+91",
    "mx-MX": "+52",
    "ar-SA": "+966",
    "gb-GB": "+44",
    "ca-CA": "+1",
    "au-AU": "+61",
    "kr-KR": "+82",
    "za-ZA": "+27",
    "cl-CL": "+56",
    "co-CO": "+57",
    "pe-PE": "+51",
    "ar-AR": "+54",
    "ve-VE": "+58",
    "bo-BO": "+591",
    "py-PY": "+595",
    "uy-UY": "+598",
    "ec-EC": "+593",
    "hn-HN": "+504",
    "ni-NI": "+505",
    "sv-SV": "+503",
    "cr-CR": "+506",
    "gt-GT": "+502",
    "do-DO": "+1",
    "cu-CU": "+53",
    "pa-PA": "+507",
    "bz-BZ": "+501",
    "jm-JM": "+1",
    "ht-HT": "+509",
    "tt-TT": "+1",
    "bb-BB": "+1",
    "gy-GY": "+592",
    "sr-SR": "+597",
    "bs-BS": "+1",
    "pr-PR": "+1",
    "dk-DK": "+45",
    "se-SE": "+46",
    "no-NO": "+47",
    "fi-FI": "+358",
    "nl-NL": "+31",
    "be-BE": "+32",
    "ch-CH": "+41",
    "at-AT": "+43",
    "pl-PL": "+48",
    "gr-GR": "+30",
    "tr-TR": "+90",
    "eg-EG": "+20",
    "ng-NG": "+234",
    "ke-KE": "+254",
    "ph-PH": "+63",
    "th-TH": "+66",
    "sg-SG": "+65",
    "my-MY": "+60",
    "id-ID": "+62"
}
